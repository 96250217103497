import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import BasicBuProfileEditPanel from "./BuProfileLeftEditPanel/BasicBuProfileEditPanel";
import CompanyBuProfileEditPanel from "./BuProfileLeftEditPanel/CompanyBuProfileEditPanel";
import BasicBuProfilePreviewPanel from "./BuProfileLeftPreviewPanel/BasicBuProfilePreviewPanel";
import CompanyBuProfilePreviewPanel from "./BuProfileLeftPreviewPanel/CompanyBuProfilePreviewPanel";

export const BuProfileLeftPanel : React.FC = () => {
    const [ buProfileState ] = useBuProfileTracked();
    const isEditBuProfile = buProfileState.isEditBuProfile;

    return <div className='content-wrapper' style={{width:"100%"}}>
        {isEditBuProfile  ?
            <>
            <BasicBuProfileEditPanel/>
            <CompanyBuProfileEditPanel/>  
            {/* <ImageBuProfileEditPanel/>               
            <div className='column' id='ColoumThree' style={isShowCompanyEditPanel?{width:"0px"}:{}} />*/}
            </>
            :
            <>
            <BasicBuProfilePreviewPanel/>
            <CompanyBuProfilePreviewPanel/>
            {/* <ImageBuProfilePreviewPanel/> 
            <div className='column' id='ColoumThree' style={isShowCompanyEditPanel?{width:"0px"}:{}} />*/}
            </>    
        }
    </div>
}