export const BuProfileConstant = {
    buProfile: {
        BUCODE:"BU Code",
        BUNAME:"BU Name",
        COUNTRY:"Country",
        ADDRESS:"Address",
        LOCAL_CURRENCY_CODE:"Local Currency Code",
        TIME_ZONE:"Time Zone",
        START_HOUR:"Storage Start Hour/Min",
        START_MIN:"Storage Start Min.",
        LOGIN_ICON:"Logo",
        LOGIN_IMAGE:"Front Page Image",
        COMPANY_CODE:"Company Code",
        COMPANY_NAME:"Company Name",
        DOC_ABBR:"Doc Abbr",
        BU_PROFILE:"BU Profile",
        OPERATING_COMPANY:"Operating Company",
        LOGIN_IMAGES:"Login Page Images",
      },
}