import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";
import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { useCallback } from "react";
import { HPHButton, InputField } from "veronica-ui-component/dist/component/core";


const BuProfileRightEditPanel:React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const buProfileVM = useBuProfileVM();
    const BUPROFILE_CONSTANT = BuProfileConstant.buProfile;
    const operatingCompany = buProfileState.currentOperatingCompany;

    const handleCancel = useCallback(() => {
        buProfileVM.onCancelCompanyEditPanel();
    }, [buProfileVM]);

    const addDataHandler = useCallback(() => {
        buProfileVM.addOperatingCompany();
    }, [buProfileVM]);

    const updateData = useCallback(() => {
        buProfileVM.updateOperatingCompany();
    }, [buProfileVM]);

    return <div className='sidebar' style={{width:"30%"}}>
        <div className='sidebar-header'>
            <div className='sidebar-title'>
                { !buProfileState.isAddOperatingCompany ? "Update Company" : "Add Company"}
                <span className='sidebar-caption'>All fields are required unless indicated as 'optional'</span>
            </div>
        </div>
            
        <div className='inner-div'>
            <InputField
                width='150px'
                maxLength={5}
                label={BUPROFILE_CONSTANT.COMPANY_CODE}
                type="text"
                value={operatingCompany.operatingCompany || ""}
                onChange={(e: any) => buProfileVM.onInputCompanyTextChange(e, 'operatingCompany')}/>
        </div>
        <div className='inner-div'>
            <InputField
                width='300px'
                maxLength={200}
                label={BUPROFILE_CONSTANT.COMPANY_NAME}
                type="text"            
                value={operatingCompany.operatingCompanyName || ""}
                onChange={(e: any) => buProfileVM.onInputCompanyTextChange(e, 'operatingCompanyName')}/>
        </div>
        <div className='inner-div'>
            <InputField
                width='150px'
                maxLength={30}
                label={BUPROFILE_CONSTANT.DOC_ABBR}
                type="text"
                value={operatingCompany.operatingCompanyNameAbbr || ""}
                onChange={(e: any) => buProfileVM.onInputCompanyTextChange(e, 'operatingCompanyNameAbbr')}/>
        </div>
        
        <div className='sidebar-action-bar'>
            <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancel} />
            { buProfileState.isAddOperatingCompany ?
            <HPHButton label={'Add'} size={'Small'} theme={'Primary'} onClick={addDataHandler} />
            :
            <HPHButton label={'Update'} size={'Small'} theme={'Primary'} onClick={updateData} />
            }
        </div>
    </div>
}

export default (BuProfileRightEditPanel);