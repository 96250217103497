import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { BuProfileProvider } from "presentation/store/BuProfle/BuProfileProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import BuProfileComp from "presentation/view/section/BuProfile/BuProfileComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const BuProfileContMain = () => {
    return <ANAInfoWrapper permission={Permission.BUPROFILE}>
        <MessageBarWrapper>
            <BuProfileProvider>
                <GridStyles/>
                <BuProfileComp/>
            </BuProfileProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}