import { DomainEntity } from "domain/entity/Domain/DomainEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { DomainRepository } from "./DomainRepo";

export const DomainRepoImpl = (): DomainRepository => {
  const url = "/v1/sysdomain/code";

  const getDomainByKey = (key: string): Promise<DomainEntity> => {
    return axiosGetData(authAxiosInstance, `${url}`, [key])
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return [];
      });
  };

  return {
    getDomainByKey: getDomainByKey,
  };
};
