import { BuOperatingCompanyEntity } from "domain/entity/BuProfile/BuProfileEntity";
import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";
import { INITIAL_OPERATING_COMPANY_COL_DEF } from "presentation/constant/BuProfile/OperatingCompanyColumnDefinition";
import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { memo, useCallback, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const CompanyBuProfilePreviewPanel : React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const buProfile = buProfileState.buProfile;
    const buProfileVM = useBuProfileVM();
    const isShowCompanyEditPanel = buProfileState.isShowCompanyEditPanel;
    const tableId = "operatingCompanyTable";
    const [tableRowSelect, setTableRowSelect] = useState<any>({});

    const onEditBuProfile = useCallback((e: any, row: BuOperatingCompanyEntity, i: string) => {    
        buProfileVM?.onEditCompanyEditPanel(row);
    }, [buProfileVM]);

    return <div className='column' id='ColoumTwo' style={{width:"66.67%"}}>
        <div className='column-inner-wrapper' style={buProfileState.isShowCompanyEditPanel ? {width:"100%"} : {width:"60%"}}>
          <div>
              <HPHTable
                id={tableId}
                columns={INITIAL_OPERATING_COMPANY_COL_DEF.map((col, index) => ({ ...col, header: col.headerName, id: index + 1, dataType: 'text' }))}
                data={buProfile.buOperatingCompanies??[]}
                addButtonId={tableId}
                onRowDoubleClick={(e: any, row: BuOperatingCompanyEntity, i: string) => onEditBuProfile(e, row, i)}
                showPaginator={false}
                editable={false}
                showDeleteButton={false}
                headerLabel="Operating Company"
                showHeaderIcons={false}
                showReloadIcon={false}
                showUploadIcon={false}
                onRowClick={(e: any, i: any) => {
                    setTableRowSelect((prevState: any) => ({
                      ...prevState,
                      [tableId]: isShowCompanyEditPanel ? { ...prevState[tableId] } : { [i]: true },
                    }));
                }}
                isRowHighligted={tableRowSelect[tableId] ? true : false}
                isRowChange={!isShowCompanyEditPanel}
              />
        </div>
        
        <div className='inner-div'>
          <div className='title' style={{marginTop:"30px"}}>{BuProfileConstant.buProfile.LOGIN_IMAGES}</div>
          <span className="customLabel">{BuProfileConstant.buProfile.LOGIN_IMAGE}</span>   
          <div className={"customContainer"}>         
            <img key="wallpaperImage" src={buProfile.loginPageWallpaperUrl??""} alt="..."  style={{width:"150px", height:"150px", marginRight:"20px"}} />
          </div>
        </div>
      </div>
    </div>;
}

export default memo(CompanyBuProfilePreviewPanel);