import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";
import { useANAInfoTracked } from "presentation/store/ANAInfo";

const BuProfileHeader: React.FC = () => {
    const [ buProfileState ] = useBuProfileTracked();
    const title = "BU Profile";
    const isEditBuProfile = buProfileState.isEditBuProfile;
    const isShowCompanyEditPanel = buProfileState.isShowCompanyEditPanel;
    const buProfileVM = useBuProfileVM();
    const [ anainfoState ] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;

    const handleClose = useCallback(() => {
        buProfileVM.onCloseEditBuProfile(buProfileState.buProfile);
    }, [buProfileVM, buProfileState.buProfile]);

    const handleSave = useCallback(() => {
        buProfileVM.updateBuProfile(buProfileState.buProfile);
    }, [buProfileVM, buProfileState.buProfile]);

    const handleEdit = useCallback(() => {
        buProfileVM.onEditBuProfile();
    }, [buProfileVM])
    
    return <div className='header'>
        <ul className='breadcrumb'>
            <HeaderTitle>{title}</HeaderTitle>
        </ul>
        {isEditBuProfile ?
        <div>
            <IconButton fileName="Icon-cross" idIcon="Icon-cross" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} 
                disabled={isShowCompanyEditPanel ? true : false} onClick={handleClose} />
            <IconButton fileName="Icon-tick" idIcon="Icon-tick" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} 
                disabled={isShowCompanyEditPanel ? true : false} onClick={handleSave} />
        </div> :
        <div>
            {
                (
                    (allowUpdate)
                )
                && <IconButton fileName="Icon-pen" idIcon="Icon-pen" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} 
                    onClick={handleEdit} />
            }
        </div>}
    </div>
}

export default memo(BuProfileHeader);