import { BuOperatingCompanyEntity, BuProfileEntity, EMPTY_BU_OPERATING_COMPANY_ENTITY, EMPTY_BU_PROFILE_ENTITY } from "domain/entity/BuProfile/BuProfileEntity";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";

export interface BuProfileDropdownOptions {
    currencyCodeDropdownOptions: DropdownProps[],
    countryDropdownOptions: DropdownProps[],
    timezoneDropdownOptions: DropdownProps[]
}

export interface BuProfileModel {
    buProfile:BuProfileEntity,
    isEditBuProfile:boolean,
    isShowCompanyEditPanel:boolean,
    currentTableRef?: RefObject<any>,
    dynamicOptions: BuProfileDropdownOptions,
    currentOperatingCompany:BuOperatingCompanyEntity,
    isAddOperatingCompany:boolean
    searchCounter: number,
    cloneData?:BuProfileEntity|null,
    allFormState?: { [x: string]: string | number | any },
    otherInformations?: { [x: string]: string }[],
    rowKeys?: string[],
	including?: { [x: string]: string }[]
}

export const EMPTY_BU_PROFILE_MODEL : BuProfileModel = {
    buProfile:EMPTY_BU_PROFILE_ENTITY,
    isEditBuProfile:false,
    isShowCompanyEditPanel:false,
    dynamicOptions: {
        currencyCodeDropdownOptions: [],
        countryDropdownOptions: [],
        timezoneDropdownOptions: []
    },
    currentOperatingCompany: EMPTY_BU_OPERATING_COMPANY_ENTITY,
    isAddOperatingCompany:false,
    searchCounter:0,
    cloneData: null,
    allFormState:{},
    otherInformations: [],
    rowKeys:[],
    including:[]
}