export interface BuOperatingCompanyEntity {
    operatingCompany:string|null,
    operatingCompanyName:string|null,
    operatingCompanyNameAbbr:string|null,
    versionStamp:number|null

    [key:string]:string | number | null 
}

export interface BuProfileEntity {
    buCode: string|null,
    buName?:string|null,
    localCurrencyCode?:string|null,
    country?:string|null,
    address?:string|null,
    timezone:string | null,
    startHour?:number | null,
    startMin?:number | null,
    loginPageWallpaper?: string | null,
    loginPageLogo?: string | null,
    loginPageWallpaperUrl?: any | null,
    loginPageLogoUrl?: any | null,
    buOperatingCompanies: BuOperatingCompanyEntity[] | null,
    defaultOperatingCompany: string,
    logoFile?: any | null,
    wallpaperFile?: any | null,
    versionStamp:number|null

    [key:string]:string | number | undefined | null | {}
}

export const EMPTY_BU_PROFILE_ENTITY : BuProfileEntity = {
    buCode:'',
    buName:'',
    localCurrencyCode:null,
    country:null,
    address:null,
    timezone:null,
    startHour:null,
    startMin:null,
    loginPageWallpaper: null,
    loginPageLogo: null,
    loginPageWallpaperUrl: null,
    loginPageLogoUrl: null,
    buOperatingCompanies: [],
    defaultOperatingCompany: '2691',
    logoFile: null,
    wallpaperFile: null,
    versionStamp:0
}

export const EMPTY_BU_OPERATING_COMPANY_ENTITY:BuOperatingCompanyEntity = {
    operatingCompany:null,
    operatingCompanyName:null,
    operatingCompanyNameAbbr:null,
    versionStamp:0
}