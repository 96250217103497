export const MasterDataType = {
    COUNTRY: "CountryEntity"
}

export interface MasterDataEntity {
    code:string,
    desc?:string|null,
    masterType:string,
    versionStamp:number|null,

    [key:string]: string | number | null | undefined
}

export const EMPTY_MASTER_DATA_ENTITY:MasterDataEntity = {
    code:"",
    desc:null,
    masterType:"",
    versionStamp:0,
}