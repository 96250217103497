import { useMemo } from "react";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { BuProfileVM } from "presentation/viewModel/BuProfile/BuProfileVM/BuProfileVM";
import { BuProfileRepoImpl } from "domain/repository/BuProfile/BuProfileRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { DomainRepoImpl } from "domain/repository/Domain/DomainRepoImpl";

export const useBuProfileVM = () => {
    const [, setBuProfileState] = useBuProfileTracked();
    const buProfileVM = useMemo(() =>
        BuProfileVM({
            dispatch: [setBuProfileState],
            buProfileRepo: BuProfileRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            domainRepo: DomainRepoImpl()
        }), [setBuProfileState])

    return buProfileVM
}