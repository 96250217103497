import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { memo, useEffect, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import BuProfileHeader from "./BuProfileHeader";
import { BuProfileLeftPanel } from "./BuProfileLeftPanel/BuProfileLeftPanel";
import BuProfileRightEditPanel from "./BuProfileRightPanel/BuProfileRightEditPanel";
import BuProfileRightPreviewPanel from "./BuProfileRightPanel/BuProfileRightPreviewPanel";

export function isSearchCriteriaSet(obj1: any, obj2: any) { return JSON.stringify(obj1) === JSON.stringify(obj2); }

const BuProfileComp : React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const isShowCompanyEditPanel = buProfileState.isShowCompanyEditPanel;
    const isEditBuProfile = buProfileState.isEditBuProfile;
    const [ anaInfoState ] =  useANAInfoTracked();

    // const memoContainerDetailModal = useMemo(() => openedContainerNo ? <ContainerDetail/> : null, [openedContainerNo]);
    const buProfileVM = useBuProfileVM();    
    const [loader, setLoader] = useState(false);

    useEffect(() => {                    
        //get bu profile
        setLoader(true); 
        const initialScreen = async() => {   
            try {
                const results = await Promise.allSettled([
                    buProfileVM.loadBuProfile(anaInfoState.operatingUnit),
                    buProfileVM.loadDropdownOptions()
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Load BU profile successful!`);
                      } else if (index === 1 && result.status === 'rejected') {
                        console.error(`Load dropdown options successful!`);
                      }
                })
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            setLoader(false);
        }).catch(error => {            
            setLoader(false);
        });       
    }, [anaInfoState.operatingUnit, buProfileVM]);

    if (loader) return <Loader Indicator="Stripe" size="Large" />;

    return <div className='buprofile-wrapper' id='buProfile'>
        <div className='vertical' id='Resizable' style={{ ...isShowCompanyEditPanel ? { width: 'calc(100% - 30%)' } : { width: '100%' } }}>
            <BuProfileHeader/>
            <BuProfileLeftPanel/>
        </div>        
        {/* {isShowCompanyEditPanel && <ResizePanel/>} */}
        {isShowCompanyEditPanel && (isEditBuProfile  &&
            <BuProfileRightEditPanel/>)
        }
        {isShowCompanyEditPanel && (!isEditBuProfile &&
            <BuProfileRightPreviewPanel/>)
        }
    </div>
}

export default memo(BuProfileComp);