export const DomainCodeList = {
  TIMEZONE: "TIMEZONE",
};

export interface DomainEntity {
  code: string | null;
  domainType: string | null;
  description?: string | null;
  sysDomainValues: DomainValueEntity[] | null;
  activeInd: string | null;
  versionStamp: number | null;

  [key: string]: string | number | undefined | null | {};
}

export const EMPTY_DOMAIN_ENTITY: DomainEntity = {
  code: "",
  domainType: "",
  description: null,
  sysDomainValues: [],
  activeInd: "Y",
  versionStamp: 0,
};

export interface DomainValueEntity {
  userInputCode: string | null;
  description: string | null;
  sysDomainValueDtls: DomainValueDtlEntity[] | null;
  activeInd: string | null;
  versionStamp: number | null;

  [key: string]: string | number | undefined | null | {};
}

export const EMPTY_DOMAIN_VALUE_ENTITY: DomainValueEntity = {
  userInputCode: "",
  description: null,
  sysDomainValueDtls: [],
  activeInd: "Y",
  versionStamp: 0,
};

export interface DomainValueDtlEntity {
  userInputCode: string | null;
  description: string | null;
  versionStamp: number | null;

  [key: string]: string | number | undefined | null;
}

export const EMPTY_DOMAIN_VALUE_DTL_ENTITY: DomainValueDtlEntity = {
  userInputCode: "",
  description: null,
  versionStamp: 0,
};
