import moment from "moment";
import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";
import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { memo } from "react";
import { HPHInputTextarea, InputDropdown, InputField, TimePicker } from "veronica-ui-component/dist/component/core";
import { SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const BasicBuProfileEditPanel: React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const buProfileVM = useBuProfileVM();
    const BUPROFILE_CONSTANT = BuProfileConstant.buProfile;
    const buProfile = buProfileState.buProfile;

    const getStartTime = (hours:number, min:number) => {
        const sysdate = moment();
        sysdate.hours(hours);
        sysdate.minutes(min);
        return sysdate
    }

    return <div className='column' id='ColoumOne'>
        <div className='column-inner-wrapper'>
            <SidebarTitle>{buProfile.buCode??""}</SidebarTitle>
            <div className='inner-div'>
            </div>
            <div className='inner-div'>
                <InputField
                    width='150px'
                    maxLength={10}
                    label={BUPROFILE_CONSTANT.BUCODE}
                    type="text"
                    disabled={true}
                    value={buProfile.buCode || ''}
                    onChange={(e: any) => buProfileVM.onInputTextChange(e, 'buCode')}/>
            </div>
            <div className='inner-div'>
                <InputDropdown
                    label={BUPROFILE_CONSTANT.TIME_ZONE}
                    width='100%'
                    inputType="freeText"
                    mode={'single'}
                    sort={false}
                    value={buProfile.timezone}
                    onChange={(e) => {
                        buProfileVM.onDropdownChange(e, 'timezone')
                    }}
                    options={buProfileState.dynamicOptions.timezoneDropdownOptions}/>
            </div>
            <div className='inner-div'>
                <InputField
                    width='100%'
                    maxLength={300}
                    label={BUPROFILE_CONSTANT.BUNAME}
                    type="text"
                    value={buProfile.buName || ""}
                    onChange={(e: any) => buProfileVM.onInputTextChange(e, 'buName')}/>
            </div>
            <div className='inner-div'>
                <InputDropdown
                    label={BUPROFILE_CONSTANT.COUNTRY}
                    width='150px'
                    inputType="freeText"
                    mode={'single'}
                    sort={false}
                    value={buProfile.country}
                    onChange={(e) => {
                        buProfileVM.onDropdownChange(e, 'country')
                    }}
                    options={buProfileState.dynamicOptions.countryDropdownOptions}/>
            </div>
            
            <div className='inner-div'>
                <InputDropdown
                    label={BUPROFILE_CONSTANT.LOCAL_CURRENCY_CODE}
                    width='150px'
                    inputType="freeText"
                    mode={'single'}
                    sort={false}
                    value={buProfile.localCurrencyCode}
                    onChange={(e) => {
                        buProfileVM.onDropdownChange(e, 'localCurrencyCode')
                    }}
                    options={buProfileState.dynamicOptions.currencyCodeDropdownOptions}/>
            </div>
            <div className='inner-div'>
                <TimePicker
                    width='150px'
                    maxLength={2}
                    label={BUPROFILE_CONSTANT.START_HOUR}
                    type="number"
                    minLength={2}
                    value={getStartTime(buProfile.startHour??0, buProfile.startMin??0).toDate()}
                    onChange={(e: any) => buProfileVM.onTimePickerChange(e, 'startHour', 'startMin')}/>
            </div>
            {/* <div className='inner-div'>
                <InputField
                    width='150px'
                    maxLength={2}
                    label={BUPROFILE_CONSTANT.START_MIN}
                    type="number"
                    minLength={2}
                    value={buProfile.startMin || 0}
                    onChange={(e: any) => buProfileVM.onInputTextChange(e, 'startHour')}/>
            </div> */}
            <div className='inner-div'>
                <HPHInputTextarea
                    label={BUPROFILE_CONSTANT.ADDRESS}
                    width="100%"
                    rows={3}               
                    resizable={false}
                    value={buProfile.address??""}
                    onChange={(e) => {buProfileVM.onTextAreaChange(e, "address")}} />
            </div>
        </div>
    </div>
}

export default memo(BasicBuProfileEditPanel);