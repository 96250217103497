import moment from "moment";
import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { memo } from "react";
import { SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const BasicBuProfilePreviewPanel: React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const BUPROFILE_CONSTANT = BuProfileConstant.buProfile;
    const buProfile = buProfileState.buProfile;

    const getStartTime = (hours:number, min:number) => {
        const sysdate = moment();
        sysdate.hours(hours);
        sysdate.minutes(min);
        return sysdate
    }

    return (
        <div className='column' id='ColoumOne'>
            <div className='column-inner-wrapper'>
                <SidebarTitle>{buProfile.buCode??""}</SidebarTitle>
                <div className='inner-div'>
                </div>

                <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.BUCODE}</div>
                    <span className='readonly-label'>{buProfile.buCode}</span>
                </div>
                <div className='inner-div'>                    
                    <div className='headings'>{BUPROFILE_CONSTANT.TIME_ZONE}</div>
                    <span className='readonly-label'>{buProfile.timezone}</span>
                </div>
                <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.BUNAME}</div>
                    <span className='readonly-label'>{buProfile.buName}</span>
                </div>
                <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.COUNTRY}</div>
                    <span className='readonly-label'>{buProfile.country}</span>
                </div>
                <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.LOCAL_CURRENCY_CODE}</div>
                    <span className='readonly-label'>{buProfile.localCurrencyCode}</span>
                </div>
                <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.START_HOUR}</div>
                    <span className='readonly-label'>{getStartTime(buProfile.startHour??0, buProfile.startMin??0).format("HH:mm")}</span>
                </div>
                <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.ADDRESS}</div>
                    <span className='readonly-label'>{buProfile.address}</span>
                </div>
                {/* <div className='inner-div'>
                    <div className='headings'>{BUPROFILE_CONSTANT.START_MIN}</div>
                    <span className='readonly-label'>{buProfile.startMin}</span>
                </div> */}

            </div>
        </div>
    );
}

export default memo(BasicBuProfilePreviewPanel);